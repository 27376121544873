<template>
  <div>
    <navigation />
    <LazySectionsHomeHero />
    <LazySectionsHomePresentation />
    <LazySectionsHomeServicesHome />
    <LazySectionsHomeClientsHome />
    <LazySectionsHomeCaseStudiesHome />
    <LazySectionsGeneralBottomcaller />
    <LazySectionsGeneralFooterMain />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useHead } from '#imports'
import navigation from '../components/sections/general/navigation.vue'

const { t } = useI18n()

useHead({
  title: t('meta.home'),
  meta: [
    { hid: 'description', name: 'description', content: t('pages.home.hero.creative-services.description') },
    { hid: 'og:title', name: 'og:title', content: t('meta.home') },
    { hid: 'og:description', name: 'og:description', content: t('pages.home.hero.creative-services.description') },
    { hid: 'twitter:title', name: 'twitter:title', content: t('meta.home') },
    { hid: 'twitter:description', name: 'twitter:description', content: t('pages.home.hero.creative-services.description') },
  ],
})
</script>
